import {getTimestampHash} from './url_timestamp_helper'
import {FST_REQUEST_AUTH_HEADER} from 'assets/js/constants'

export const setFSTRequestAuthHeader = async (config: any, uri: string) => {
  const result = await getTimestampHash(uri)
  if (result && result.hash && result.timestamp) {
    if (config) {
      config.headers = config.options?.headers || {}
      config.headers[FST_REQUEST_AUTH_HEADER.HASH] = result.hash
      config.headers[FST_REQUEST_AUTH_HEADER.TIMESTAMP] = result.timestamp
    }
  }
  return config
}
